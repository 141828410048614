<template>
  <div class="fixed-layout">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'fixed-layout'
}
</script>

<style lang="scss" scoped>
.fixed-layout {
  margin: 17vh 0px 0px 0px;
  position: relative;
  height: 75vh;
}
</style>